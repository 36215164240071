import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo"
import Layout from "../components/layouts/layout";

import { MDXRenderer } from "gatsby-plugin-mdx";

export const query = graphql`
 query ($slug: String) {
  mdx(slug: { eq: $slug }) {
   frontmatter {
    path
    title
    description
    pageId
    heroImage
    pageConfig {
      pageTitle
      heroImage
    }
    date(formatString: "MMMM D, YYYY")
   }
   parent {
    ... on File {
     modifiedTime(formatString: "MMMM D, YYYY")
    }
   }
   slug
   body
  }

  wpPage {
    id
    isFrontPage
    link
    date
    content
    title
    slug
  }

 }
`;

const MdxPage = ({data}) => {

    const pageData = data.wpPage;
    console.log('WordPress page data: ', pageData)

    const { frontmatter, parent, body } = data.mdx;

    console.log('frontmatter: ', frontmatter);
    console.log('mdx data: ', data.mdx);

    const { title, date } = frontmatter;
    const postStatus = date === parent.modifiedTime ? "Published on" : "Updated on";
   
    return (
     <Layout>
       <div className="container-fluid body-container main">
        {/* <SEO data={seoData} /> */}
        <div className="row">
          <main className="col-sm-12">
            <h1>{title}</h1>
            <MDXRenderer>{body}</MDXRenderer>
          </main>
        </div>
      </div>
     </Layout>
    );
};

export default MdxPage;